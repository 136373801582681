import { render, staticRenderFns } from "./InvoiceReports.vue?vue&type=template&id=fd2bc242&scoped=true&"
import script from "./InvoiceReports.vue?vue&type=script&lang=js&"
export * from "./InvoiceReports.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd2bc242",
  null
  
)

export default component.exports