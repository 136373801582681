<template>
    <custom-vue-table class="pl-3 pr-3" title="Invoice Reports" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                      :extra-params="{ start_date, status, end_date }">
        <template #filters>
            <div class="btn-group ml-3">
                <drop-btn text="Filters" class="ml-3" icon="fa fa-filter" size="md" dropdown-width="25r">
                    <div class="p-3">
                        <validated-input type="date" label="Start Date" name="Start Date"
                                         v-model="start_date"/>
                        <validated-input type="date" label="End Date" name="End Date"
                                         v-model="end_date"/>
                    </div>
                </drop-btn>
                <btn color="primary" size="sm" @click="InvoiceReportDownload" text="Download"/>
            </div>
        </template>
    </custom-vue-table>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name : 'InvoiceReportDownload',
    data () {
        return {
            start_date : '',
            end_date   : '',
            listUrl    : urls.salesAdmin.invoices.list,
            fields     : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'created_date',
                    sortField : 'created_date',
                    title     : 'Created Date'
                },
                {
                    name      : 'customer',
                    sortField : 'customer',
                    title     : 'Customer'
                },
                {
                    name      : 'sub_total',
                    sortField : 'sub_total',
                    title     : 'Sub Total'
                },
                {
                    name      : 'total_amount',
                    sortField : 'total_amount',
                    title     : 'Total Amount'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        async InvoiceReportDownload () {
            this.loading = true;
            const component = this;
            const response = await axios.form(urls.salesAdmin.reports.invoices, {
                start_date : component.start_date,
                end_date   : component.end_date,
                status     : component.status
            });
            const json = response.data;
            if (json.url) {
                window.open(json.url);
                component.$notify('Downloaded Successfully', 'Downloaded', {
                    type : 'success'
                });
            } else {
                component.$notify('Failed to downloaded', 'Failed', {
                    type : 'danger'
                });
            }
        }
    }
};
</script>

<style scoped>

</style>
